@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
.c-text--small {
  font-size: 0.9rem;
}
@media (min-width: 1200px) {
  .c-text--small {
    font-size: 1rem;
  }
}

/**
 * Container
 */
.container,
.alignwide {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .container,
  .alignwide {
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container,
  .alignwide {
    max-width: 1210px;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 14px;
}

body {
  color: #000000;
  font-weight: 400;
  line-height: 1.85em;
  font-family: "Montserrat", Helvetica Neue, Helvetica, sans-serif;
  overflow-x: hidden;
  font-size: 1rem;
  /* smartphones, touchscreens */
}
@media (min-width: 1200px) {
  body {
    font-size: 1.25rem;
  }
}
@media (hover: none) and (pointer: coarse) {
  body.menu-open {
    overflow: hidden;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  font-weight: 300;
  margin-bottom: 1.8rem;
  line-height: 1.1em;
}
h1 strong, .h1 strong,
h2 strong, .h2 strong,
h3 strong, .h3 strong {
  font-weight: 700;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 700;
  margin-bottom: 1.2rem;
  line-height: 1.4em;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #35914C;
}
h1 a, .h1 a,
h2 a, .h2 a,
h3 a, .h3 a,
h4 a, .h4 a,
h5 a, .h5 a,
h6 a, .h6 a {
  text-decoration: none;
}
h1 a:hover, h1 a:focus, .h1 a:hover, .h1 a:focus,
h2 a:hover,
h2 a:focus, .h2 a:hover, .h2 a:focus,
h3 a:hover,
h3 a:focus, .h3 a:hover, .h3 a:focus,
h4 a:hover,
h4 a:focus, .h4 a:hover, .h4 a:focus,
h5 a:hover,
h5 a:focus, .h5 a:hover, .h5 a:focus,
h6 a:hover,
h6 a:focus, .h6 a:hover, .h6 a:focus {
  color: #000000;
}
footer h1, footer .h1,
footer h2, footer .h2,
footer h3, footer .h3,
footer h4, footer .h4,
footer h5, footer .h5,
footer h6, footer .h6 {
  color: #000000;
}

h1, .h1 {
  position: relative;
  line-height: 1em;
  font-size: 2.6rem;
}
@media (min-width: 576px) {
  h1, .h1 {
    font-size: 2.9rem;
  }
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.125rem;
  }
}

h2, .h2 {
  font-size: 2rem;
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.5rem;
  }
}

h3, .h3 {
  font-size: 1.55rem;
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: 1.2rem;
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.375rem;
  }
}

h5, .h5 {
  font-size: 1rem;
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.25rem;
  }
}

h6, .h6 {
  font-size: 0.9rem;
}
@media (min-width: 1200px) {
  h6, .h6 {
    font-size: 1rem;
  }
}

p {
  margin: 1em 0 1.5em 0;
}

a {
  color: #35914C;
  text-decoration: underline;
  transition: color ease-in-out 0.25s, background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
}
a:hover, a:active, a:focus {
  color: #1D6D39;
  text-decoration: none;
}

img,
svg {
  width: 100%;
  height: auto;
}

::-moz-selection { /* Code for Firefox */
  color: #000000;
  background: rgba(53, 145, 76, 0.4);
}

::selection {
  color: #000000;
  background: rgba(53, 145, 76, 0.4);
}

main > .wp-block-group {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 0 15px;
}
main > .wp-block-group:first-child {
  margin-top: 0;
}
@media (min-width: 1200px) {
  main > .wp-block-group {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  main > .wp-block-group:first-child {
    margin-top: 0;
  }
}
.home main > .wp-block-group:first-child {
  margin-top: 3rem;
}
@media (min-width: 1200px) {
  .home main > .wp-block-group:first-child {
    margin-top: 6rem;
  }
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 0.875rem;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

.has-background.has-grey-background-color,
.has-background-dim.has-grey-background-color {
  background-color: #EAEAEA;
}
.has-background.has-green-background-color,
.has-background-dim.has-green-background-color {
  background-color: #35914C;
}
.has-background.has-dark-green-background-color,
.has-background-dim.has-dark-green-background-color {
  background-color: #1D6D39;
}
.has-background.has-yellow-background-color,
.has-background-dim.has-yellow-background-color {
  background-color: #F7D45F;
}

.has-text-color.has-grey-color {
  color: #EAEAEA;
}
.has-text-color.has-green-color {
  color: #35914C;
}
.has-text-color.has-dark-green-color {
  color: #1D6D39;
}
.has-text-color.has-yellow-color {
  color: #F7D45F;
}

.v--background-grey {
  background-color: #EAEAEA;
}
.v--background-grey h1, .v--background-grey .h1,
.v--background-grey h2, .v--background-grey .h2,
.v--background-grey h3, .v--background-grey .h3,
.v--background-grey h4, .v--background-grey .h4,
.v--background-grey h5, .v--background-grey .h5,
.v--background-grey h6, .v--background-grey .h6,
.v--background-grey p {
  color: #ffffff;
}

.v--background-green {
  background-color: #35914C;
}
.v--background-green h1, .v--background-green .h1,
.v--background-green h2, .v--background-green .h2,
.v--background-green h3, .v--background-green .h3,
.v--background-green h4, .v--background-green .h4,
.v--background-green h5, .v--background-green .h5,
.v--background-green h6, .v--background-green .h6,
.v--background-green p {
  color: #ffffff;
}

.v--background-dark-green {
  background-color: #1D6D39;
}
.v--background-dark-green h1, .v--background-dark-green .h1,
.v--background-dark-green h2, .v--background-dark-green .h2,
.v--background-dark-green h3, .v--background-dark-green .h3,
.v--background-dark-green h4, .v--background-dark-green .h4,
.v--background-dark-green h5, .v--background-dark-green .h5,
.v--background-dark-green h6, .v--background-dark-green .h6,
.v--background-dark-green p {
  color: #ffffff;
}

.v--background-yellow {
  background-color: #F7D45F;
}
.v--background-yellow h1, .v--background-yellow .h1,
.v--background-yellow h2, .v--background-yellow .h2,
.v--background-yellow h3, .v--background-yellow .h3,
.v--background-yellow h4, .v--background-yellow .h4,
.v--background-yellow h5, .v--background-yellow .h5,
.v--background-yellow h6, .v--background-yellow .h6,
.v--background-yellow p {
  color: #ffffff;
}

div[class*=has-background] {
  padding: 3rem 15px;
  margin-bottom: 0;
  margin-top: 0;
}
@media (min-width: 1200px) {
  div[class*=has-background] {
    padding: 6rem 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.c-services {
  max-width: 1050px;
  margin: 0 auto;
}
.c-services p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.c-services .wp-block-column {
  padding: 1rem;
  position: relative;
}
.c-services .wp-block-column .wp-block-image {
  display: block;
}
.c-services .wp-block-column .wp-block-image img {
  display: inline-block;
  transition: transform ease-in-out 0.25s;
}
.c-services .wp-block-column:hover .wp-block-image img, .c-services .wp-block-column:focus .wp-block-image img {
  transform: scale(1.05);
}
.c-services .wp-block-column a:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}
@media (min-width: 768px) {
  .c-services .wp-block-column {
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .c-services .wp-block-column {
    padding: 1rem;
  }
}
.c-services .wp-block-column .wp-block-image {
  text-align: center;
}
.c-services .wp-block-column .wp-block-image img {
  max-width: 120px;
}

.o-section-service .wp-block-image {
  text-align: center;
}
.o-section-service .wp-block-image img {
  max-width: 270px;
}

.c-header__info,
.c-nav--primary,
.c-select--language {
  z-index: 1055;
}

.c-site-logo {
  position: relative;
}
.c-site-logo figure {
  max-width: 250px;
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  -ms-transition: opacity 250ms ease-in-out;
  -o-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
.c-site-logo .normal {
  opacity: 1;
}
.c-site-logo .light {
  opacity: 0;
  position: absolute;
  top: 0;
}
.menu-open .c-site-logo .normal {
  opacity: 0;
}
.menu-open .c-site-logo .light {
  opacity: 1;
}

.c-select--language {
  display: none;
  font-weight: 500;
}
.c-select--language a {
  color: #000000;
  text-decoration: none;
}
.c-select--language a:hover, .c-select--language a:focus {
  color: #35914C;
  text-decoration: underline;
}
.c-select--language .active {
  color: #35914C;
  text-decoration: underline;
}
.menu-open .c-select--language a,
.menu-open .c-select--language span {
  color: rgba(255, 255, 255, 0.6);
}
.menu-open .c-select--language .active {
  color: #ffffff;
}

footer {
  font-size: 1rem;
}
@media (min-width: 1200px) {
  footer {
    font-size: 1.125rem;
  }
}
footer a {
  color: #000000;
  text-decoration: none;
}
footer a:hover, footer a:focus {
  text-decoration: underline;
}

.c-footer--logo {
  max-width: 220px;
}

.c-footer__social .c-icon {
  display: block;
  width: 20px;
  height: 20px;
  transition: opacity ease-in-out 0.15s;
}
.c-footer__social .c-icon:hover, .c-footer__social .c-icon:active, .c-footer__social .c-icon:focus {
  opacity: 0.7;
}

.btn,
.c-btn,
button,
.button,
.wp-block-button,
.wp-block-button.is-style-fill,
.wp-block-button.is-style-outline {
  display: inline-block;
  position: relative;
  font-weight: 800;
  text-align: center;
  background-color: transparent;
  line-height: 1.1rem;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  width: 100%;
  text-decoration: none;
}
.btn:focus,
.c-btn:focus,
button:focus,
.button:focus,
.wp-block-button:focus,
.wp-block-button.is-style-fill:focus,
.wp-block-button.is-style-outline:focus {
  outline: none;
}
.btn .has-background.has-grey-background-color,
.c-btn .has-background.has-grey-background-color,
button .has-background.has-grey-background-color,
.button .has-background.has-grey-background-color,
.wp-block-button .has-background.has-grey-background-color,
.wp-block-button.is-style-fill .has-background.has-grey-background-color,
.wp-block-button.is-style-outline .has-background.has-grey-background-color {
  border-color: #EAEAEA;
}
.btn .has-background.has-green-background-color,
.c-btn .has-background.has-green-background-color,
button .has-background.has-green-background-color,
.button .has-background.has-green-background-color,
.wp-block-button .has-background.has-green-background-color,
.wp-block-button.is-style-fill .has-background.has-green-background-color,
.wp-block-button.is-style-outline .has-background.has-green-background-color {
  border-color: #35914C;
}
.btn .has-background.has-dark-green-background-color,
.c-btn .has-background.has-dark-green-background-color,
button .has-background.has-dark-green-background-color,
.button .has-background.has-dark-green-background-color,
.wp-block-button .has-background.has-dark-green-background-color,
.wp-block-button.is-style-fill .has-background.has-dark-green-background-color,
.wp-block-button.is-style-outline .has-background.has-dark-green-background-color {
  border-color: #1D6D39;
}
.btn .has-background.has-yellow-background-color,
.c-btn .has-background.has-yellow-background-color,
button .has-background.has-yellow-background-color,
.button .has-background.has-yellow-background-color,
.wp-block-button .has-background.has-yellow-background-color,
.wp-block-button.is-style-fill .has-background.has-yellow-background-color,
.wp-block-button.is-style-outline .has-background.has-yellow-background-color {
  border-color: #F7D45F;
}
.btn .has-text-color.has-grey-color,
.c-btn .has-text-color.has-grey-color,
button .has-text-color.has-grey-color,
.button .has-text-color.has-grey-color,
.wp-block-button .has-text-color.has-grey-color,
.wp-block-button.is-style-fill .has-text-color.has-grey-color,
.wp-block-button.is-style-outline .has-text-color.has-grey-color {
  color: #EAEAEA;
}
.btn .has-text-color.has-green-color,
.c-btn .has-text-color.has-green-color,
button .has-text-color.has-green-color,
.button .has-text-color.has-green-color,
.wp-block-button .has-text-color.has-green-color,
.wp-block-button.is-style-fill .has-text-color.has-green-color,
.wp-block-button.is-style-outline .has-text-color.has-green-color {
  color: #35914C;
}
.btn .has-text-color.has-dark-green-color,
.c-btn .has-text-color.has-dark-green-color,
button .has-text-color.has-dark-green-color,
.button .has-text-color.has-dark-green-color,
.wp-block-button .has-text-color.has-dark-green-color,
.wp-block-button.is-style-fill .has-text-color.has-dark-green-color,
.wp-block-button.is-style-outline .has-text-color.has-dark-green-color {
  color: #1D6D39;
}
.btn .has-text-color.has-yellow-color,
.c-btn .has-text-color.has-yellow-color,
button .has-text-color.has-yellow-color,
.button .has-text-color.has-yellow-color,
.wp-block-button .has-text-color.has-yellow-color,
.wp-block-button.is-style-fill .has-text-color.has-yellow-color,
.wp-block-button.is-style-outline .has-text-color.has-yellow-color {
  color: #F7D45F;
}
.v--background-green .btn:hover, .v--background-green .btn:active, .v--background-green .btn:focus, .v--background-dark-green .btn:hover, .v--background-dark-green .btn:active, .v--background-dark-green .btn:focus, .has-green-background-color .btn:hover, .has-green-background-color .btn:active, .has-green-background-color .btn:focus,
.v--background-green .c-btn:hover,
.v--background-green .c-btn:active,
.v--background-green .c-btn:focus,
.v--background-dark-green .c-btn:hover,
.v--background-dark-green .c-btn:active,
.v--background-dark-green .c-btn:focus,
.has-green-background-color .c-btn:hover,
.has-green-background-color .c-btn:active,
.has-green-background-color .c-btn:focus,
.v--background-green button:hover,
.v--background-green button:active,
.v--background-green button:focus,
.v--background-dark-green button:hover,
.v--background-dark-green button:active,
.v--background-dark-green button:focus,
.has-green-background-color button:hover,
.has-green-background-color button:active,
.has-green-background-color button:focus,
.v--background-green .button:hover,
.v--background-green .button:active,
.v--background-green .button:focus,
.v--background-dark-green .button:hover,
.v--background-dark-green .button:active,
.v--background-dark-green .button:focus,
.has-green-background-color .button:hover,
.has-green-background-color .button:active,
.has-green-background-color .button:focus,
.v--background-green .wp-block-button:hover,
.v--background-green .wp-block-button:active,
.v--background-green .wp-block-button:focus,
.v--background-dark-green .wp-block-button:hover,
.v--background-dark-green .wp-block-button:active,
.v--background-dark-green .wp-block-button:focus,
.has-green-background-color .wp-block-button:hover,
.has-green-background-color .wp-block-button:active,
.has-green-background-color .wp-block-button:focus,
.v--background-green .wp-block-button.is-style-fill:hover,
.v--background-green .wp-block-button.is-style-fill:active,
.v--background-green .wp-block-button.is-style-fill:focus,
.v--background-dark-green .wp-block-button.is-style-fill:hover,
.v--background-dark-green .wp-block-button.is-style-fill:active,
.v--background-dark-green .wp-block-button.is-style-fill:focus,
.has-green-background-color .wp-block-button.is-style-fill:hover,
.has-green-background-color .wp-block-button.is-style-fill:active,
.has-green-background-color .wp-block-button.is-style-fill:focus,
.v--background-green .wp-block-button.is-style-outline:hover,
.v--background-green .wp-block-button.is-style-outline:active,
.v--background-green .wp-block-button.is-style-outline:focus,
.v--background-dark-green .wp-block-button.is-style-outline:hover,
.v--background-dark-green .wp-block-button.is-style-outline:active,
.v--background-dark-green .wp-block-button.is-style-outline:focus,
.has-green-background-color .wp-block-button.is-style-outline:hover,
.has-green-background-color .wp-block-button.is-style-outline:active,
.has-green-background-color .wp-block-button.is-style-outline:focus {
  border-color: #ffffff;
  background-color: transparent;
}
@media (max-width: 500px) {
  .btn,
  .c-btn,
  button,
  .button,
  .wp-block-button,
  .wp-block-button.is-style-fill,
  .wp-block-button.is-style-outline {
    width: 100%;
    display: block;
    text-align: left;
  }
}

.btn,
.c-btn,
.wp-block-button.is-style-fill .wp-block-button__link {
  display: inline-block;
  position: relative;
  font-weight: 800;
  text-align: center;
  background-color: transparent;
  line-height: 1.1rem;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  background-color: #35914C;
  border: 2px solid #35914C;
  padding: 0.9rem 3rem 0.9rem 1.5rem;
  height: 50px;
  width: auto;
  border-radius: 25px;
  position: relative;
  color: #ffffff;
}
.btn:focus,
.c-btn:focus,
.wp-block-button.is-style-fill .wp-block-button__link:focus {
  outline: none;
}
.btn .has-background.has-grey-background-color,
.c-btn .has-background.has-grey-background-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-background.has-grey-background-color {
  border-color: #EAEAEA;
}
.btn .has-background.has-green-background-color,
.c-btn .has-background.has-green-background-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-background.has-green-background-color {
  border-color: #35914C;
}
.btn .has-background.has-dark-green-background-color,
.c-btn .has-background.has-dark-green-background-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-background.has-dark-green-background-color {
  border-color: #1D6D39;
}
.btn .has-background.has-yellow-background-color,
.c-btn .has-background.has-yellow-background-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-background.has-yellow-background-color {
  border-color: #F7D45F;
}
.btn .has-text-color.has-grey-color,
.c-btn .has-text-color.has-grey-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-text-color.has-grey-color {
  color: #EAEAEA;
}
.btn .has-text-color.has-green-color,
.c-btn .has-text-color.has-green-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-text-color.has-green-color {
  color: #35914C;
}
.btn .has-text-color.has-dark-green-color,
.c-btn .has-text-color.has-dark-green-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-text-color.has-dark-green-color {
  color: #1D6D39;
}
.btn .has-text-color.has-yellow-color,
.c-btn .has-text-color.has-yellow-color,
.wp-block-button.is-style-fill .wp-block-button__link .has-text-color.has-yellow-color {
  color: #F7D45F;
}
.v--background-green .btn:hover, .v--background-green .btn:active, .v--background-green .btn:focus, .v--background-dark-green .btn:hover, .v--background-dark-green .btn:active, .v--background-dark-green .btn:focus, .has-green-background-color .btn:hover, .has-green-background-color .btn:active, .has-green-background-color .btn:focus,
.v--background-green .c-btn:hover,
.v--background-green .c-btn:active,
.v--background-green .c-btn:focus,
.v--background-dark-green .c-btn:hover,
.v--background-dark-green .c-btn:active,
.v--background-dark-green .c-btn:focus,
.has-green-background-color .c-btn:hover,
.has-green-background-color .c-btn:active,
.has-green-background-color .c-btn:focus,
.v--background-green .wp-block-button.is-style-fill .wp-block-button__link:hover,
.v--background-green .wp-block-button.is-style-fill .wp-block-button__link:active,
.v--background-green .wp-block-button.is-style-fill .wp-block-button__link:focus,
.v--background-dark-green .wp-block-button.is-style-fill .wp-block-button__link:hover,
.v--background-dark-green .wp-block-button.is-style-fill .wp-block-button__link:active,
.v--background-dark-green .wp-block-button.is-style-fill .wp-block-button__link:focus,
.has-green-background-color .wp-block-button.is-style-fill .wp-block-button__link:hover,
.has-green-background-color .wp-block-button.is-style-fill .wp-block-button__link:active,
.has-green-background-color .wp-block-button.is-style-fill .wp-block-button__link:focus {
  border-color: #ffffff;
  background-color: transparent;
}
@media (max-width: 500px) {
  .btn,
  .c-btn,
  .wp-block-button.is-style-fill .wp-block-button__link {
    width: 100%;
    display: block;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .btn,
  .c-btn,
  .wp-block-button.is-style-fill .wp-block-button__link {
    font-size: 1.125rem;
  }
}
.btn:after,
.c-btn:after,
.wp-block-button.is-style-fill .wp-block-button__link:after {
  content: "";
  background-image: url("../images/arrow-white.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 13px;
  width: 16px;
  height: 16px;
  right: 1.5rem;
  position: absolute;
  transition: transform ease-in-out 150ms;
}
.btn:hover, .btn:active, .btn:focus,
.c-btn:hover,
.c-btn:active,
.c-btn:focus,
.wp-block-button.is-style-fill .wp-block-button__link:hover,
.wp-block-button.is-style-fill .wp-block-button__link:active,
.wp-block-button.is-style-fill .wp-block-button__link:focus {
  color: #35914C;
  background-color: #ffffff;
}
.btn:hover:after, .btn:active:after, .btn:focus:after,
.c-btn:hover:after,
.c-btn:active:after,
.c-btn:focus:after,
.wp-block-button.is-style-fill .wp-block-button__link:hover:after,
.wp-block-button.is-style-fill .wp-block-button__link:active:after,
.wp-block-button.is-style-fill .wp-block-button__link:focus:after {
  background-image: url("../images/arrow-green.svg");
  transform: translateX(0.2rem);
}

.btn,
.c-btn,
.wp-block-button.is-style-outline .wp-block-button__link {
  display: inline-block;
  position: relative;
  font-weight: 800;
  text-align: center;
  background-color: transparent;
  line-height: 1.1rem;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  padding: 0.9rem 3rem 0.9rem 1.5rem;
  height: 50px;
  width: auto;
  color: #35914C;
  border-radius: 25px;
  position: relative;
}
.btn:focus,
.c-btn:focus,
.wp-block-button.is-style-outline .wp-block-button__link:focus {
  outline: none;
}
.btn .has-background.has-grey-background-color,
.c-btn .has-background.has-grey-background-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-background.has-grey-background-color {
  border-color: #EAEAEA;
}
.btn .has-background.has-green-background-color,
.c-btn .has-background.has-green-background-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-background.has-green-background-color {
  border-color: #35914C;
}
.btn .has-background.has-dark-green-background-color,
.c-btn .has-background.has-dark-green-background-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-background.has-dark-green-background-color {
  border-color: #1D6D39;
}
.btn .has-background.has-yellow-background-color,
.c-btn .has-background.has-yellow-background-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-background.has-yellow-background-color {
  border-color: #F7D45F;
}
.btn .has-text-color.has-grey-color,
.c-btn .has-text-color.has-grey-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-text-color.has-grey-color {
  color: #EAEAEA;
}
.btn .has-text-color.has-green-color,
.c-btn .has-text-color.has-green-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-text-color.has-green-color {
  color: #35914C;
}
.btn .has-text-color.has-dark-green-color,
.c-btn .has-text-color.has-dark-green-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-text-color.has-dark-green-color {
  color: #1D6D39;
}
.btn .has-text-color.has-yellow-color,
.c-btn .has-text-color.has-yellow-color,
.wp-block-button.is-style-outline .wp-block-button__link .has-text-color.has-yellow-color {
  color: #F7D45F;
}
.v--background-green .btn:hover, .v--background-green .btn:active, .v--background-green .btn:focus, .v--background-dark-green .btn:hover, .v--background-dark-green .btn:active, .v--background-dark-green .btn:focus, .has-green-background-color .btn:hover, .has-green-background-color .btn:active, .has-green-background-color .btn:focus,
.v--background-green .c-btn:hover,
.v--background-green .c-btn:active,
.v--background-green .c-btn:focus,
.v--background-dark-green .c-btn:hover,
.v--background-dark-green .c-btn:active,
.v--background-dark-green .c-btn:focus,
.has-green-background-color .c-btn:hover,
.has-green-background-color .c-btn:active,
.has-green-background-color .c-btn:focus,
.v--background-green .wp-block-button.is-style-outline .wp-block-button__link:hover,
.v--background-green .wp-block-button.is-style-outline .wp-block-button__link:active,
.v--background-green .wp-block-button.is-style-outline .wp-block-button__link:focus,
.v--background-dark-green .wp-block-button.is-style-outline .wp-block-button__link:hover,
.v--background-dark-green .wp-block-button.is-style-outline .wp-block-button__link:active,
.v--background-dark-green .wp-block-button.is-style-outline .wp-block-button__link:focus,
.has-green-background-color .wp-block-button.is-style-outline .wp-block-button__link:hover,
.has-green-background-color .wp-block-button.is-style-outline .wp-block-button__link:active,
.has-green-background-color .wp-block-button.is-style-outline .wp-block-button__link:focus {
  border-color: #ffffff;
  background-color: transparent;
}
@media (max-width: 500px) {
  .btn,
  .c-btn,
  .wp-block-button.is-style-outline .wp-block-button__link {
    width: 100%;
    display: block;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .btn,
  .c-btn,
  .wp-block-button.is-style-outline .wp-block-button__link {
    font-size: 1.125rem;
  }
}
.btn:after,
.c-btn:after,
.wp-block-button.is-style-outline .wp-block-button__link:after {
  content: "";
  background-image: url("../images/arrow-green.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 13px;
  width: 16px;
  height: 16px;
  right: 1.5rem;
  position: absolute;
  transition: transform ease-in-out 150ms;
}
.btn:hover, .btn:active, .btn:focus,
.c-btn:hover,
.c-btn:active,
.c-btn:focus,
.wp-block-button.is-style-outline .wp-block-button__link:hover,
.wp-block-button.is-style-outline .wp-block-button__link:active,
.wp-block-button.is-style-outline .wp-block-button__link:focus {
  color: #ffffff;
  background-color: #35914C;
  border-color: #35914C;
}
.btn:hover:after, .btn:active:after, .btn:focus:after,
.c-btn:hover:after,
.c-btn:active:after,
.c-btn:focus:after,
.wp-block-button.is-style-outline .wp-block-button__link:hover:after,
.wp-block-button.is-style-outline .wp-block-button__link:active:after,
.wp-block-button.is-style-outline .wp-block-button__link:focus:after {
  background-image: url("../images/arrow-white.svg");
  transform: translateX(0.2rem);
}
.btn.c-btn--border:hover, .btn.c-btn--border:active, .btn.c-btn--border:focus,
.c-btn.c-btn--border:hover,
.c-btn.c-btn--border:active,
.c-btn.c-btn--border:focus,
.wp-block-button.is-style-outline .wp-block-button__link.c-btn--border:hover,
.wp-block-button.is-style-outline .wp-block-button__link.c-btn--border:active,
.wp-block-button.is-style-outline .wp-block-button__link.c-btn--border:focus {
  border-color: #ffffff;
  background-color: transparent;
}

.wp-block-button,
.wp-block-button.is-style-fill,
.wp-block-button.is-style-outline {
  width: 100%;
}
@media (min-width: 576px) {
  .wp-block-button,
  .wp-block-button.is-style-fill,
  .wp-block-button.is-style-outline {
    width: auto;
  }
}

@media (max-width: 552px) {
  .wp-block-button {
    width: 100%;
    display: block;
  }
}
.wp-block-button__link {
  display: flex !important;
  align-items: center;
}

/**
 * Gravity forms
 */
.hidden_label .gfield_label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.validation_error {
  display: none !important;
}

.gform_description {
  display: block;
  margin-bottom: 1em;
}

.gform_fields {
  list-style: none;
  margin: 0;
  padding: 0;
}
.gform_fields li:before {
  content: none !important;
}

.gfield {
  margin-bottom: 1.5rem;
  width: 100%;
}
.gfield .gfield_label {
  display: block;
  font-weight: 700;
}
.gfield .gfield_description {
  margin: 10px 0;
}
.gfield .gfield_required {
  color: #E67C00;
  margin-left: 0.25em;
}

.gfield_error [aria-invalid=true] {
  border-color: #E67C00 !important;
}
.gfield_error .validation_message {
  color: #E67C00;
  display: none;
}

.gfield_radio,
.gfield_checkbox {
  list-style: none;
  padding: 0;
}
.gfield_radio input[type=radio],
.gfield_radio input[type=checkbox],
.gfield_checkbox input[type=radio],
.gfield_checkbox input[type=checkbox] {
  margin-right: 5px;
}

.gform_ajax_spinner {
  display: inline-block;
  width: 2rem !important;
  height: 2rem !important;
  margin: 0 1rem;
}

.gform_hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

::placeholder {
  color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.c-form button,
.c-form input[type=submit] {
  display: inline-block;
  position: relative;
  font-weight: 800;
  text-align: center;
  background-color: transparent;
  line-height: 1.1rem;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  background-color: #35914C;
  border: 2px solid #35914C;
  padding: 0.9rem 3rem 0.9rem 1.5rem;
  height: 50px;
  width: auto;
  border-radius: 25px;
  position: relative;
  color: #ffffff;
}
.c-form button:focus,
.c-form input[type=submit]:focus {
  outline: none;
}
.c-form button .has-background.has-grey-background-color,
.c-form input[type=submit] .has-background.has-grey-background-color {
  border-color: #EAEAEA;
}
.c-form button .has-background.has-green-background-color,
.c-form input[type=submit] .has-background.has-green-background-color {
  border-color: #35914C;
}
.c-form button .has-background.has-dark-green-background-color,
.c-form input[type=submit] .has-background.has-dark-green-background-color {
  border-color: #1D6D39;
}
.c-form button .has-background.has-yellow-background-color,
.c-form input[type=submit] .has-background.has-yellow-background-color {
  border-color: #F7D45F;
}
.c-form button .has-text-color.has-grey-color,
.c-form input[type=submit] .has-text-color.has-grey-color {
  color: #EAEAEA;
}
.c-form button .has-text-color.has-green-color,
.c-form input[type=submit] .has-text-color.has-green-color {
  color: #35914C;
}
.c-form button .has-text-color.has-dark-green-color,
.c-form input[type=submit] .has-text-color.has-dark-green-color {
  color: #1D6D39;
}
.c-form button .has-text-color.has-yellow-color,
.c-form input[type=submit] .has-text-color.has-yellow-color {
  color: #F7D45F;
}
.v--background-green .c-form button:hover, .v--background-green .c-form button:active, .v--background-green .c-form button:focus, .v--background-dark-green .c-form button:hover, .v--background-dark-green .c-form button:active, .v--background-dark-green .c-form button:focus, .has-green-background-color .c-form button:hover, .has-green-background-color .c-form button:active, .has-green-background-color .c-form button:focus,
.v--background-green .c-form input[type=submit]:hover,
.v--background-green .c-form input[type=submit]:active,
.v--background-green .c-form input[type=submit]:focus,
.v--background-dark-green .c-form input[type=submit]:hover,
.v--background-dark-green .c-form input[type=submit]:active,
.v--background-dark-green .c-form input[type=submit]:focus,
.has-green-background-color .c-form input[type=submit]:hover,
.has-green-background-color .c-form input[type=submit]:active,
.has-green-background-color .c-form input[type=submit]:focus {
  border-color: #ffffff;
  background-color: transparent;
}
@media (max-width: 500px) {
  .c-form button,
  .c-form input[type=submit] {
    width: 100%;
    display: block;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .c-form button,
  .c-form input[type=submit] {
    font-size: 1.125rem;
  }
}
.c-form button:after,
.c-form input[type=submit]:after {
  content: "";
  background-image: url("../images/arrow-white.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 13px;
  width: 16px;
  height: 16px;
  right: 1.5rem;
  position: absolute;
  transition: transform ease-in-out 150ms;
}
.c-form button:hover, .c-form button:active, .c-form button:focus,
.c-form input[type=submit]:hover,
.c-form input[type=submit]:active,
.c-form input[type=submit]:focus {
  color: #35914C;
  background-color: #ffffff;
}
.c-form button:hover:after, .c-form button:active:after, .c-form button:focus:after,
.c-form input[type=submit]:hover:after,
.c-form input[type=submit]:active:after,
.c-form input[type=submit]:focus:after {
  background-image: url("../images/arrow-green.svg");
  transform: translateX(0.2rem);
}

input,
select,
textarea {
  outline: 0;
  border: 0;
  margin: 0;
}

input[type=date],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
textarea {
  height: 50px;
  background: #ffffff;
  border: 2px solid #35914C;
  border-radius: 25px;
  width: 100%;
  max-width: 100%;
  padding: 0.9rem 1.5rem;
  line-height: 1.1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #35914C;
  font-weight: 400;
}

textarea {
  min-height: 128px;
}

.c-form--call-back {
  max-width: 440px;
  font-size: 1rem;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .c-form--call-back input {
    border-radius: 25px 0 0 25px;
    border-right: none;
  }
}
.c-form--call-back button {
  border-radius: 25px;
  min-width: 180px;
}
@media (min-width: 992px) {
  .c-form--call-back button {
    border-radius: 0 25px 25px 0;
  }
}

.gfield_radio li {
  position: relative;
}
.gfield_radio li input[type=radio] {
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  top: 0.25rem;
  left: 0;
  margin: auto;
  border-radius: 10px;
  border: 2px solid #35914C;
  background-color: #ffffff;
  -webkit-transition: background-color ease-in-out 0.2s;
  transition: background-color ease-in-out 0.2s;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  pointer-events: visible;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .gfield_radio li input[type=radio] {
    top: 0.5rem;
  }
}
.gfield_radio li input[type=radio]:before {
  content: "";
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  position: absolute;
  top: 3px;
  left: 3px;
  height: 7px;
  width: 10px;
  transform: rotate(-45deg) scale(0);
  -webkit-transition: border-color ease-in-out 0.2s, transform ease-in-out 0.3s;
  transition: border-color ease-in-out 0.2s, transform ease-in-out 0.3s;
}
.gfield_radio li input[type=radio]:checked {
  background-color: #35914C;
}
.gfield_radio li input[type=radio]:checked:before {
  border-color: #ffffff;
  transform: rotate(-45deg) scale(1);
}
.gfield_radio li label {
  padding-left: 2rem;
  margin-bottom: 0;
  pointer-events: visible;
  cursor: pointer;
}

.gfield_checkbox {
  position: relative;
}
.gfield_checkbox input[type=checkbox] {
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  top: 0.25rem;
  left: 0;
  margin: auto;
  border: 2px solid #35914C;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #ffffff;
  -webkit-transition: background-color ease-in-out 0.2s;
  transition: background-color ease-in-out 0.2s;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  pointer-events: visible;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .gfield_checkbox input[type=checkbox] {
    top: 0.5rem;
  }
}
.gfield_checkbox input[type=checkbox]:before {
  content: "";
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  position: absolute;
  top: 3px;
  left: 3px;
  height: 7px;
  width: 10px;
  transform: rotate(-45deg) scale(0);
  -webkit-transition: border-color ease-in-out 0.2s, transform ease-in-out 0.3s;
  transition: border-color ease-in-out 0.2s, transform ease-in-out 0.3s;
}
.gfield_checkbox input[type=checkbox]:checked {
  background-color: #35914C;
}
.gfield_checkbox input[type=checkbox]:checked:before {
  border-color: #ffffff;
  transform: rotate(-45deg) scale(1);
}
.gfield_checkbox label {
  padding-left: 2rem;
  margin-bottom: 0;
  pointer-events: visible;
  cursor: pointer;
}

.ginput_complex input:not(:last-child) {
  margin-bottom: 1rem;
}

ol.o-list--numbers {
  margin: 0;
  padding: 0;
  padding-left: 2.5rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
@media (min-width: 1200px) {
  ol.o-list--numbers {
    padding-left: 2.7rem;
  }
}
ol.o-list--numbers:first-child {
  counter-reset: customlistcounter;
}
ol.o-list--numbers li {
  position: relative;
  margin-bottom: 2rem;
  padding: 0.6rem 0.6rem 0.6rem 1rem;
  color: #35914C;
  font-weight: 600;
  font-size: 1rem;
  counter-increment: customlistcounter;
}
@media (min-width: 1200px) {
  ol.o-list--numbers li {
    font-size: 1.25rem;
  }
}
ol.o-list--numbers li:before {
  content: counter(customlistcounter) " ";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 32.5px;
  height: 32.5px;
  position: absolute;
  background-color: #35914C;
  left: -2rem;
  top: 5px;
}
@media (min-width: 1200px) {
  ol.o-list--numbers li:before {
    font-size: 1.375rem;
  }
}
@media (min-width: 1200px) {
  ol.o-list--numbers li:before {
    top: 9px;
    left: -2rem;
  }
}
ol.o-list--numbers li:after {
  content: "";
  display: block;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  border: 2px solid #35914C;
  min-height: 50px;
  width: calc(100% + 2.5rem);
  position: absolute;
  left: -2.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media (min-width: 1200px) {
  ol.o-list--numbers li:after {
    width: calc(100% + 2.7rem);
    left: -2.7rem;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }
}
ol.o-list--numbers li::marker {
  content: "";
}

.wp-block-columns div[class*=v--background-] {
  margin: 0 !important;
  padding: 1.5rem;
  flex-basis: initial !important;
  width: 100%;
}
@media (min-width: 600px) and (max-width: 781px) {
  .wp-block-columns div[class*=v--background-] {
    flex-basis: initial !important;
  }
}
@media (min-width: 768px) {
  .wp-block-columns div[class*=v--background-] {
    padding: 1rem;
    width: 33.3%;
  }
}
@media (min-width: 992px) {
  .wp-block-columns div[class*=v--background-] {
    padding: 2rem;
  }
}
@media (min-width: 1200px) {
  .wp-block-columns div[class*=v--background-] {
    padding: 3rem;
  }
}

.wp-block-columns {
  margin-bottom: 0;
}
@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: wrap;
  }
}

.wp-block-column:first-child {
  margin-bottom: 2rem;
}
@media (min-width: 600px) {
  .wp-block-column:first-child {
    margin-bottom: 0;
  }
}

.wp-block-group__inner-container {
  max-width: 1140px;
  margin: 0 auto;
}
.wp-block-group__inner-container h1,
.wp-block-group__inner-container h2 {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .wp-block-group__inner-container h1,
  .wp-block-group__inner-container h2 {
    margin-bottom: 3rem;
  }
}
.wp-block-group__inner-container .wp-block-button {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .wp-block-group__inner-container .wp-block-button {
    margin-top: 3rem;
  }
}

.c-nav ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.c-nav ul li {
  display: inline-block;
}

.navbar {
  padding: 0;
  z-index: 1054;
  position: inherit;
}
@media (min-width: 992px) {
  .navbar {
    position: relative;
  }
}

.navbar-toggler {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  cursor: pointer;
  overflow: visible;
  z-index: 20;
  flex-shrink: 0;
  right: 0;
  -webkit-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
  -moz-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
  -ms-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
  -o-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
  transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
  margin-right: 0 !important;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #000000;
}
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.navbar-toggler i {
  display: block;
  background-color: #000000;
  width: 12px;
  height: 2px;
  transition: all 250ms ease-in-out;
}
.navbar-toggler i:nth-of-type(1) {
  -webkit-transform: translateY(-0.25rem) translateX(-0.2rem);
  transform: translateY(-0.25rem) translateX(-0.2rem);
}
.navbar-toggler i:nth-of-type(3) {
  -webkit-transform: translateY(0.25rem) translateX(0.2rem);
  transform: translateY(0.25rem) translateX(0.2rem);
}
.navbar-toggler:hover {
  border-color: #35914C;
}
.navbar-toggler:hover i {
  background-color: #35914C;
}
.navbar-toggler:hover i:nth-of-type(1) {
  -webkit-transform: translateY(-0.25rem) translateX(0rem);
  transform: translateY(-0.25rem) translateX(0rem);
}
.navbar-toggler:hover i:nth-of-type(3) {
  -webkit-transform: translateY(0.25rem) translateX(0rem);
  transform: translateY(0.25rem) translateX(0rem);
}

.navbar-toggler[aria-expanded=true] {
  border-color: #ffffff;
}
.navbar-toggler[aria-expanded=true] i {
  background-color: #ffffff;
  width: 22px;
}
.navbar-toggler[aria-expanded=true] i:nth-of-type(1) {
  transform: rotate(-45deg) translateY(0.1rem) translateX(-0.1rem);
}
.navbar-toggler[aria-expanded=true] i:nth-of-type(2) {
  opacity: 0;
}
.navbar-toggler[aria-expanded=true] i:nth-of-type(3) {
  transform: rotate(45deg) translateY(-0.1rem) translateX(-0.1rem);
}

.navbar-collapse {
  display: block !important;
  visibility: hidden;
  position: absolute;
  z-index: 19;
  left: 0;
  right: 0;
  top: 0;
  background-color: #35914C;
  opacity: 0;
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  -ms-transition: opacity 250ms ease-in-out;
  -o-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
@media (min-width: 992px) {
  .navbar-collapse {
    position: fixed;
    width: 100vw;
    height: 100vh !important;
  }
}
.navbar-collapse .navbar-nav--wrapper {
  height: 100vh;
  padding-top: 80px;
}
.menu-open .navbar-collapse {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

.navbar-collapse > .container {
  padding: 73px 15px 35px 15px;
}
@media (min-width: 768px) {
  .navbar-collapse > .container {
    padding: 90px 15px 35px 15px;
  }
}
@media (min-width: 992px) {
  .navbar-collapse > .container {
    padding: 140px 15px 115px 15px;
  }
}
.navbar-collapse > .container > .c-item--nav {
  width: 100%;
}
.navbar-collapse li {
  text-align: center;
  margin-bottom: 1rem;
}
.navbar-collapse li a {
  position: relative;
  padding: 0.25rem 0;
  text-decoration: none;
  font-size: 1.55rem;
  font-weight: 700;
  color: #ffffff;
}
@media (min-width: 1200px) {
  .navbar-collapse li a {
    font-size: 1.75rem;
  }
}
.navbar-collapse li a:hover, .navbar-collapse li a:focus, .navbar-collapse li a:active {
  color: rgba(255, 255, 255, 0.6);
}
.navbar-collapse .sub-menu {
  display: none;
  background: transparent;
  border: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.navbar-collapse .sub-menu li {
  display: block;
}
.navbar-collapse .sub-menu li a {
  font-size: 1.2rem;
  font-weight: 400;
}
@media (min-width: 1200px) {
  .navbar-collapse .sub-menu li a {
    font-size: 1.375rem;
  }
}
.navbar-collapse .menu-item-has-children > a:after {
  content: "";
  position: absolute;
  right: -2rem;
  bottom: 10px;
  width: 15px;
  height: 15px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
}
.navbar-collapse .menu-item-has-children.show .sub-menu {
  display: block;
}
.navbar-collapse .menu-item-has-children.show > a:after {
  right: -1.75rem;
  transform: rotate(-135deg) translate(0px, 0px);
}

nav.c-nav--primary li {
  line-height: 1.1rem;
  margin-right: 1rem;
  display: none;
}
@media (min-width: 576px) {
  nav.c-nav--primary li {
    display: inline-block;
  }
}
nav.c-nav--primary li a {
  text-decoration: none;
  position: relative;
  padding: 0.25rem 0;
}
nav.c-nav--primary li a:hover:after, nav.c-nav--primary li a:focus:after, nav.c-nav--primary li a:active:after {
  background-color: #35914C;
  width: 100%;
  opacity: 1;
}
.menu-open nav.c-nav--primary li a {
  color: #ffffff;
}
.menu-open nav.c-nav--primary li a:hover:after, .menu-open nav.c-nav--primary li a:focus:after, .menu-open nav.c-nav--primary li a:active:after {
  background-color: #ffffff;
}