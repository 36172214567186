// Font sizes

@mixin font-body {
    font-size: 1rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 1.25rem;
    }
}

@mixin font-h1 {
    font-size: 2.6rem;

    @media (min-width: breakpoint(sm)) {
        font-size: 2.9rem;
    }

    @media (min-width: breakpoint(xl)) {
        font-size: 3.125rem;
    }
}

@mixin font-h2 {
    font-size: 2rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 2.5rem;
    }
}

@mixin font-h3 {
    font-size: 1.55rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 1.75rem;
    }
}

@mixin font-h4 {
    font-size: 1.2rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 1.375rem;
    }
}

@mixin font-h5 {
    font-size: 1rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 1.25rem;
    }
}

@mixin font-h6 {
    font-size: .9rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 1rem;
    }
}

@mixin font-big {
    font-size: 1.4rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 1.5625rem;
    }
}

@mixin font-small {
    font-size: 1rem;

    @media (min-width: breakpoint(xl)) {
        font-size: 1.125rem;
    }
}

.c-text--small {
    @include font-h6;
}
