.c-nav {
    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
            display: inline-block;
        }
    }
}

.navbar {
    padding: 0;
    z-index: 1054;
    position: inherit;

    @media (min-width: breakpoint(lg)) {
        position: relative;
    }
}

.navbar-toggler {
    width: 40px;
    height: 40px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    cursor: pointer;
    overflow: visible;
    z-index: 20;
    flex-shrink: 0;
    right: 0;
    -webkit-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
    -moz-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
    -ms-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
    -o-transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
    transition: transform 250ms ease-in-out, border-color 250ms ease-in-out;
    margin-right: 0 !important;
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid $c-black;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    i {
        display: block;
        background-color: $c-black;
        width: 12px;
        height: 2px;
        transition: all 250ms ease-in-out;

        &:nth-of-type(1) {
            -webkit-transform: translateY(-.25rem) translateX(-.2rem);
            transform: translateY(-.25rem) translateX(-.2rem);
        }

        &:nth-of-type(3) {
            -webkit-transform: translateY(.25rem) translateX(.2rem);
            transform: translateY(.25rem) translateX(.2rem);
        }
    }

    &:hover {
        border-color: $c-green;

        i {
            background-color: $c-green;

            &:nth-of-type(1) {
                -webkit-transform: translateY(-.25rem) translateX(0rem);
                transform: translateY(-.25rem) translateX(0rem);
            }

            &:nth-of-type(3) {
                -webkit-transform: translateY(.25rem) translateX(0rem);
                transform: translateY(.25rem) translateX(0rem);
            }
        }
    }
}

.navbar-toggler[aria-expanded="true"] {
    border-color: $c-white;

    i {
        background-color: $c-white;
        width: 22px;

        &:nth-of-type(1) {
            transform: rotate(-45deg) translateY(0.1rem) translateX(-.1rem);
        }

        &:nth-of-type(2) {
            opacity: 0;
        }

        &:nth-of-type(3) {
            transform: rotate(45deg) translateY(-0.1rem) translateX(-.1rem);
        }
    }
}

.navbar-collapse {
    display: block !important;
    visibility: hidden;
    position: absolute;
    z-index: 19;
    left: 0;
    right: 0;
    top: 0;
    background-color: $c-green;
    opacity: 0;
    -webkit-transition: opacity 250ms ease-in-out;
    -moz-transition: opacity 250ms ease-in-out;
    -ms-transition: opacity 250ms ease-in-out;
    -o-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out;

    @media (min-width: breakpoint(lg)) {
        position: fixed;
        width: 100vw;
        height: 100vh !important;
    }

    .navbar-nav--wrapper {
        height: 100vh;
        padding-top: 80px;
    }

    .menu-open & {
        display: block !important;
        opacity: 1;
        visibility: visible;
    }
}

.navbar-collapse {
    > .container {
        padding: 73px 15px 35px 15px;

        @media (min-width: breakpoint(md)) {
            padding: 90px 15px 35px 15px;
        }

        @media (min-width: breakpoint(lg)) {
            padding: 140px 15px 115px 15px;
        }

        > .c-item--nav {
            width: 100%;
        }
    }

    li {
        text-align: center;
        margin-bottom: 1rem;

        a {
            position: relative;
            padding: .25rem 0;
            text-decoration: none;
            @include font-h3;
            font-weight: 700;
            color: $c-white;

            &:hover,
            &:focus,
            &:active {
                color: rgba($c-white, .6);
            }
        }
    }

    .sub-menu {
        display: none;
        background: transparent;
        border: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        li {
            display: block;

            a {
                @include font-h4;
                font-weight: 400;
            }
        }
    }

    .menu-item-has-children {
        & > a {
            &:after {
                content: '';
                position: absolute;
                right: -2rem;
                bottom: 10px;
                width: 15px;
                height: 15px;
                border: solid $c-white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg) translateX(-50%);
                transform: rotate(45deg) translateX(-50%);
            }
        }

        &.show {
            .sub-menu {
                display: block;
            }

            > a {
                &:after {
                    right: -1.75rem;
                    transform: rotate(-135deg) translate(0px,0px);
                }
            }
        }
    }
}

nav.c-nav--primary {
    li {
        line-height: 1.1rem;
        margin-right: 1rem;
        display: none;

        @media (min-width: breakpoint(sm)) {
            display: inline-block;
        }

        a {
            text-decoration: none;
            position: relative;
            padding: .25rem 0;

            &:hover,
            &:focus,
            &:active {
                &:after {
                    background-color: $c-green;
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }

    .menu-open & {
        li {
            a {
                color: $c-white;

                &:hover,
                &:focus,
                &:active {
                    &:after {
                        background-color: $c-white;
                    }
                }
            }
        }
    }
}
