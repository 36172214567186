.c-services  {
    max-width: 1050px;
    margin: 0 auto;

    p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .wp-block-column {
        padding: 1rem;
        position: relative;

        .wp-block-image {
            display: block;
            img {
                display: inline-block;
                transition: transform ease-in-out .25s;
            }
        }

        &:hover,
        &:focus {
            .wp-block-image {
                img {
                    transform: scale(1.05);
                }
            }
        }

        a {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        @media (min-width: breakpoint(md)) {
            padding: 0;
        }

        @media (min-width: breakpoint(xl)) {
            padding: 1rem;
        }

        .wp-block-image {
            text-align: center;

            img {
                max-width: 120px;
            }
        }
    }
}


.o-section-service {
    .wp-block-image {
        text-align: center;

        img {
            max-width: 270px;
        }
    }
}