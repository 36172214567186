footer {
    @include font-small;

    a {
        color: $c-black;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.c-footer--logo {
    max-width: 220px;
}

.c-footer__social {
    .c-icon {
        display: block;
        width: 20px;
        height: 20px;
        transition: opacity ease-in-out .15s;

        &:hover,
        &:active,
        &:focus {
            opacity: .7;
        }
    }
}
