.v--background-grey {
    background-color: $c-grey;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p {
        color: $c-white;
    }
}

.v--background-green {
    background-color: $c-green;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p {
        color: $c-white;
    }
}

.v--background-dark-green {
    background-color: $c-dark-green;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p {
        color: $c-white;
    }
}

.v--background-yellow {
    background-color: $c-yellow;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p {
        color: $c-white;
    }
}



div[class*='has-background'] {
    padding: 3rem 15px;
    margin-bottom: 0;
    margin-top: 0;

    @media (min-width: breakpoint(xl)) {
        padding: 6rem 15px;
        margin-bottom: 0;
        margin-top: 0;
    }
}