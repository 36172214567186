
div[class*='v--background-'] {

    .wp-block-columns & {
        margin: 0 !important;
        padding: 1.5rem;
        flex-basis: initial !important;
        width: 100%;

        @media (min-width: 600px) and (max-width: 781px) {
            flex-basis: initial !important;
        }

        @media (min-width: breakpoint(md)) {
            padding: 1rem;
            width: 33.3%;
        }

        @media (min-width: breakpoint(lg)) {
            padding: 2rem;
        }

        @media (min-width: breakpoint(xl)) {
            padding: 3rem;
        }
    }

}

.wp-block-columns {
    margin-bottom: 0;
    @media (min-width: 782px) {
        flex-wrap: wrap;
    }
}


.wp-block-column:first-child {
    margin-bottom: 2rem;

    @media (min-width: 600px) {
        margin-bottom: 0;
    }
}


.wp-block-group__inner-container {
    max-width: 1140px;
    margin: 0 auto;

    h1,
    h2 {
        margin-bottom: 2rem;

        @media (min-width: breakpoint(md)) {
            margin-bottom: 3rem;
        }
    }

    .wp-block-button {
        margin-top: 2rem;
        margin-bottom: 1rem;

        @media (min-width: breakpoint(md)) {
            margin-top: 3rem;
        }
    }
}