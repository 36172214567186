.c-header__info,
.c-nav--primary,
.c-select--language {
    z-index: 1055;
}

.c-site-logo {
    position: relative;

    figure {
        max-width: 250px;
        -webkit-transition: opacity 250ms ease-in-out;
        -moz-transition: opacity 250ms ease-in-out;
        -ms-transition: opacity 250ms ease-in-out;
        -o-transition: opacity 250ms ease-in-out;
        transition: opacity 250ms ease-in-out;
    }

    .normal {
        opacity: 1;
    }

    .light {
        opacity: 0;
        position: absolute;
        top: 0;
    }

    .menu-open & {
        .normal {
            opacity: 0;
        }

        .light {
            opacity: 1;
        }
    }
}

.c-select--language {
    display:none;
    font-weight: 500;

    a {
        color: $c-black;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $c-green;
            text-decoration: underline;
        }
    }

    .active {
        color: $c-green;
        text-decoration: underline;
    }

    .menu-open & {
        a,
        span {
            color: rgba($c-white, .6);
        }

        .active {
            color: $c-white;
        }
    }
}
