html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 14px;
}

body {
    color: $c-black;
    font-weight: 400;
    line-height: 1.85em;
    font-family: $ff-body;
    overflow-x: hidden;
    @include font-body;

    /* smartphones, touchscreens */
    @media (hover: none) and (pointer: coarse) {
        &.menu-open {
            overflow: hidden;
        }
    }
}

h1, .h1,
h2, .h2,
h3, .h3 {
    font-weight: 300;
    margin-bottom: 1.8rem;
    line-height: 1.1em;

    strong {
        font-weight: 700;
    }
}

h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: 700;
    margin-bottom: 1.2rem;
    line-height: 1.4em;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    color: $c-green;

    a {
        text-decoration: none;

        &:hover,
        &:focus {
            color: $c-black;
        }
    }

    footer & {
        color: $c-black;
    }
}

h1, .h1 {
    position: relative;
    line-height: 1em;
    @include font-h1;
}

h2, .h2 {
    @include font-h2;
}

h3, .h3 {
    @include font-h3;
}

h4, .h4 {
    @include font-h4;
}

h5, .h5 {
    @include font-h5;
}

h6, .h6 {
    @include font-h6;
}

p {
    margin: 1em 0 1.5em 0;
}

a {
    color: $c-green;
    text-decoration: underline;
    transition: color ease-in-out .25s, background-color ease-in-out .25s, border-color ease-in-out .25s;

    &:hover,
    &:active,
    &:focus {
        color: $c-dark-green;
        text-decoration: none;
    }
}

img,
svg {
    width: 100%;
    height: auto;
}

::-moz-selection { /* Code for Firefox */
    color: $c-black;
    background: rgba($c-green, .4);
}

::selection {
    color: $c-black;
    background: rgba($c-green, .4);
}


main {
   > .wp-block-group {
       margin-top: 3rem;
       margin-bottom: 3rem;
       padding: 0 15px;

       &:first-child {
           margin-top: 0;
       }

       @media (min-width: breakpoint(xl)) {
           margin-top: 6rem;
           margin-bottom: 6rem;

           &:first-child {
               margin-top: 0;
           }
       }
   }

    .home & {
        > .wp-block-group {
            &:first-child {
                margin-top: 3rem;

                @media (min-width: breakpoint(xl)) {
                    margin-top: 6rem;
                }
            }
        }
    }
}
