@mixin button {
    display: inline-block;
    position: relative;
    font-weight: 800;
    text-align: center;
    background-color: transparent;
    line-height: 1.1rem;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s;
    width: 100%;
    text-decoration: none;

    &:focus {
        outline: none;
    }


    .has-background {
        &.has-grey-background-color {
            border-color: $c-grey;
        }

        &.has-green-background-color {
            border-color: $c-green;
        }

        &.has-dark-green-background-color {
            border-color: $c-dark-green;
        }

        &.has-yellow-background-color {
            border-color: $c-yellow;
        }
    }

    .has-text-color {
        &.has-grey-color {
            color: $c-grey;
        }

        &.has-green-color {
            color: $c-green;
        }

        &.has-dark-green-color {
            color: $c-dark-green;
        }

        &.has-yellow-color {
            color: $c-yellow;
        }
    }

    .v--background-green &,
    .v--background-dark-green &,
    .has-green-background-color & {

        &:hover,
        &:active,
        &:focus {
            border-color: $c-white;
            background-color: transparent;
        }
    }

    @media (max-width: 500px) {
        width: 100%;
        display: block;
        text-align: left;
    }
}

@mixin button-primary {
    @include button;
    @include font-small;
    background-color: $c-green;
    border: 2px solid $c-green;
    padding: .9rem 3rem .9rem 1.5rem;
    height: 50px;
    width: auto;
    border-radius: 25px;
    position: relative;
    color: $c-white;

    &:after {
        content: '';
        background-image: url("../images/arrow-white.svg");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 13px;
        width: 16px;
        height: 16px;
        right: 1.5rem;
        position: absolute;
        transition: transform ease-in-out 150ms;

    }

    &:hover,
    &:active,
    &:focus {
        color: $c-green;
        background-color: $c-white;

        &:after {
            background-image: url("../images/arrow-green.svg");
            transform: translateX(.2rem);
        }

    }
}

@mixin button-secondary {
    @include button;
    @include font-small;
    background-color: $c-white;
    border: 2px solid $c-white;
    padding: .9rem 3rem .9rem 1.5rem;
    height: 50px;
    width: auto;
    color: $c-green;
    border-radius: 25px;
    position: relative;

    &:after {
        content: '';
        background-image: url("../images/arrow-green.svg");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 13px;
        width: 16px;
        height: 16px;
        right: 1.5rem;
        position: absolute;
        transition: transform ease-in-out 150ms;

    }

    &:hover,
    &:active,
    &:focus {
        color: $c-white;
        background-color: $c-green;
        border-color: $c-green;

        &:after {
            background-image: url("../images/arrow-white.svg");
            transform: translateX(.2rem);
        }
    }

    &.c-btn--border {

        &:hover,
        &:active,
        &:focus {
            border-color: $c-white;
            background-color: transparent;
        }
    }
}

.btn,
// Backward compatibility
.c-btn,
button,
.button,
.wp-block-button,
.wp-block-button.is-style-fill,
.wp-block-button.is-style-outline {
    @include button;
}

.btn,
// Backward compatibility
.c-btn,
.wp-block-button.is-style-fill .wp-block-button__link {
    @include button-primary;
}

.btn,
// Backward compatibility
.c-btn,
.wp-block-button.is-style-outline .wp-block-button__link {
    @include button-secondary;
}

.wp-block-button,
.wp-block-button.is-style-fill,
.wp-block-button.is-style-outline {
    width: 100%;

    @media (min-width: breakpoint(sm)) {
        width: auto;
    }
}

/// wordpress btn wrapper
@media (max-width: 552px) {
    .wp-block-button {
        width: 100%;
        display: block;
    }
}

.wp-block-button__link {
    display: flex !important;
    align-items: center;
}