/**
 * Gravity forms
 */
.hidden_label {
    .gfield_label {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0 0 0 0);
        border: 0;
    }
}

.validation_error {
    display: none !important; // Hide response output
}

.gform_description {
    display: block;
    margin-bottom: 1em;
}

.gform_fields {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        &:before {
            content: none !important;
        }
    }
}

.gfield {
    margin-bottom: 1.5rem;
    width: 100%;

    .gfield_label {
        display: block;
        font-weight: 700;
    }

    .gfield_description {
        margin: 10px 0;
    }

    .gfield_required {
        color: $c-orange;
        margin-left: .25em;
    }
}

.gfield_error {
    [aria-invalid="true"] {
        border-color: $c-orange !important;
    }

    .validation_message {
        color: $c-orange;
        display: none;
    }
}

.gfield_radio,
.gfield_checkbox {
    list-style: none;
    padding: 0;

    input[type="radio"],
    input[type="checkbox"] {
        margin-right: 5px;
    }
}

.gform_ajax_spinner {
    display: inline-block;
    width: 2rem !important;
    height: 2rem !important;
    margin: 0 1rem;
}

.gform_hidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
}

::placeholder {
    color: rgba($c-black, .5);
    opacity: 1;
}

:-ms-input-placeholder {
    color: rgba($c-black, .5);
}

::-ms-input-placeholder {
    color: rgba($c-black, .5);
}

.c-form {
    button,
    input[type="submit"] {
        @include button-primary;
    }
}

input,
select,
textarea {
    outline: 0;
    border: 0;
    margin: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
    height: 50px;
    background: $c-white;
    border: 2px solid $c-green;
    border-radius: 25px;
    width: 100%;
    max-width: 100%;
    padding: .9rem 1.5rem;
    line-height: 1.1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: $c-green;
    font-weight: 400;
}

textarea {
    min-height: 128px;
}

.c-form--call-back {
    max-width: 440px;
    font-size: 1rem;
    margin-bottom: 2rem;

    input {
        @media (min-width: breakpoint(lg)) {
            border-radius: 25px 0 0 25px;
            border-right: none;
        }
    }

    button {
        border-radius: 25px;
        min-width: 180px;

        @media (min-width: breakpoint(lg)) {
            border-radius: 0 25px 25px 0;
        }
    }
}


.gfield_radio {
    li {
        position: relative;

        input[type="radio"] {
            position: absolute;
            width: 1.3rem;
            height: 1.3rem;
            top: .25rem;
            left: 0;
            margin: auto;
            border-radius: 10px;
            border: 2px solid $c-green;
            background-color: $c-white;
            -webkit-transition: background-color ease-in-out .2s;
            transition: background-color ease-in-out .2s;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            pointer-events: visible;
            cursor: pointer;

            @media (min-width: breakpoint(xl)) {
                top: .5rem;
            }

            &:before {
                content: '';
                border-left: 2px solid transparent;
                border-bottom: 2px solid transparent;
                position: absolute;
                top: 3px;
                left: 3px;
                height: 7px;
                width: 10px;
                transform: rotate(-45deg) scale(0);
                -webkit-transition: border-color ease-in-out .2s, transform ease-in-out .3s;
                transition: border-color ease-in-out .2s, transform ease-in-out .3s;
            }
        }

        input[type="radio"]:checked {
            background-color: $c-green;

            &:before {
                border-color: $c-white;
                transform: rotate(-45deg) scale(1);
            }
        }

        label {
            padding-left: 2rem;
            margin-bottom: 0;
            pointer-events: visible;
            cursor: pointer;
        }
    }
}

.gfield_checkbox {
    position: relative;

    input[type="checkbox"] {
        position: absolute;
        width: 1.3rem;
        height: 1.3rem;
        top: .25rem;
        left: 0;
        margin: auto;
        border: 2px solid $c-green;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        background-color: $c-white;
        -webkit-transition: background-color ease-in-out .2s;
        transition: background-color ease-in-out .2s;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        pointer-events: visible;
        cursor: pointer;

        @media (min-width: breakpoint(xl)) {
            top: .5rem;
        }

        &:before {
            content: '';
            border-left: 2px solid transparent;
            border-bottom: 2px solid transparent;
            position: absolute;
            top: 3px;
            left: 3px;
            height: 7px;
            width: 10px;
            transform: rotate(-45deg) scale(0);
            -webkit-transition: border-color ease-in-out .2s, transform ease-in-out .3s;
            transition: border-color ease-in-out .2s, transform ease-in-out .3s;
        }
    }

    input[type="checkbox"]:checked {
        background-color: $c-green;

        &:before {
            border-color: $c-white;
            transform: rotate(-45deg) scale(1);
        }
    }

    label {
        padding-left: 2rem;
        margin-bottom: 0;
        pointer-events: visible;
        cursor: pointer;
    }
}

.ginput_complex {
    input:not(:last-child) {
        margin-bottom: 1rem;
    }
}
