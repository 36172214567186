/**
 * Container
 */

.container,
.alignwide {
    margin: 0 auto;

    @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }

    @media (min-width: breakpoint(xl)) {
        max-width: 1210px;
    }
}
