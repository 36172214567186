ol.o-list--numbers {
    margin: 0;
    padding: 0;
    padding-left: 2.5rem;
    margin-top: .6rem;
    margin-bottom: .6rem;

    @media (min-width: breakpoint(xl)) {
        padding-left: 2.7rem;
    }

    &:first-child {
        counter-reset: customlistcounter;
    }

    li {
        position: relative;
        margin-bottom: 2rem;
        padding: .6rem .6rem .6rem 1rem;;
        color: $c-green;
        font-weight: 600;
        @include font-h5;

        counter-increment: customlistcounter;

        &:before {
            content: counter(customlistcounter) " ";
            display: flex;
            justify-content: center;
            align-items: center;
            color: $c-white;
            @include font-h4;
            font-weight: 700;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            width: 32.5px;
            height: 32.5px;
            position: absolute;
            background-color: $c-green;
            left: -2rem;
            top: 5px;

            @media (min-width: breakpoint(xl)) {
                top: 9px;
                left: -2rem;
            }
        }

        &:after {
            content: '';
            display: block;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 25px;
            border: 2px solid $c-green;
            min-height: 50px;
            width: calc(100% + 2.5rem);
            position: absolute;
            left: -2.5rem;
            top: 0;
            bottom: 0;
            margin: auto;

            @media (min-width: breakpoint(xl)) {
                width: calc(100% + 2.7rem);
                left: -2.7rem;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                border-radius: 30px;
            }
        }

        &::marker {
            content: '';
        }
    }
}
